import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useLocation } from "@reach/router";
import useOnClickOutside from "use-onclickoutside";

/* eslint-disable */
import {
  up as mediaUp,
  down as mediaDown,
  between as mediaBetween,
  only as mediaOnly,
} from "styled-breakpoints";
/* eslint-enable */

import Hamburger from "./Hamburger";
import { Chevron } from "../assets/Svg";

const NavStyles = styled.nav`
  font-size: 1.6rem;
  color: ${p => p.theme.primary};

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  /* nav { */
  /*   background: #222; */
  /*   padding: 0 15px; */
  /* } */

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    &:visited {
      color: inherit;
    }
  }

  .menu,
  .submenu {
    list-style-type: none;
  }

  .submenu-active .submenu {
    display: block;
  }

  .submenu {
    display: none;
    padding: 0.5rem 0;
    background: rgba(255, 255, 255, 0.4);
  }

  .menu {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
  }

  .menu li a {
    display: block;
    padding: 1rem;
  }

  .menu li.toggle a {
    padding: 0.75rem 1rem;
    /* border: 1px solid blue; */
    border-radius: 0.4rem;
    background: rgba(255, 255, 255, 0.25);
  }

  .toggle {
    order: 1;
    font-size: 24px;
    padding: 0 1rem;
  }

  .item {
    order: 3;
    width: 100%;
    text-align: center;
    position: relative;
    display: none;
  }

  .menu.toggle-open .item {
    display: initial;
  }

  a svg {
    width: 1em;
    height: 1em;
    vertical-align: bottom;
  }

  ${mediaUp("tablet")} {
    .menu li a {
      &[aria-current],
      &:hover {
        background-color: ${p => p.theme.activeBackgroundColor};
        color: ${p => p.theme.activeColor};
      }
      padding: 1.5rem 1.5rem 2rem 1.5rem;
    }
    .submenu {
      padding: 0;
      z-index: 9999;
    }
    .item {
      display: initial;
      width: auto;
    }
    .toggle {
      display: none;
    }
    .submenu {
      position: absolute;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      width: 10em;
      text-align: left;
      background: ${p => p.theme.gradientTo};
      border-radius: 0.4rem;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      overflow: hidden;
      li a {
        padding: 1.5rem;
      }
    }
  }
`;

export default function Nav({ className }) {
  const [isActive, setActive] = useState(false);
  const [toggleOpen, setToggle] = useState(false);
  const location = useLocation();

  const ref = React.useRef(null);

  useEffect(() => {
    setActive(false);
    setToggle(false);
  }, [location.pathname]);

  useOnClickOutside(ref, () => setActive(false));

  return (
    <NavStyles role="navigation" className={className}>
      <ul className={`menu ${toggleOpen ? "toggle-open" : ""}`} ref={ref}>
        <li className="toggle">
          <a href="#" onClick={() => setToggle(!toggleOpen)}>
            <Hamburger open={toggleOpen} />
          </a>
        </li>
        <li className="item">
          <Link to="/">About Us</Link>
        </li>
        <li className={`item has-submenu ${isActive ? "submenu-active" : ""}`}>
          <a
            role="button"
            {...(location.pathname.startsWith("/services")
              ? { "aria-current": "page" }
              : {})}
            aria-haspopup="true"
            aria-expanded={isActive}
            onClick={() => setActive(!isActive)}
          >
            Services
            {Chevron}
          </a>
          <ul className="submenu">
            <li className="subitem">
              <Link to="/services/personal-care/">Personal Care</Link>
            </li>
            <li className="subitem">
              <Link to="/services/medication/">Medication</Link>
            </li>
            <li className="subitem">
              <Link to="/services/shopping/">Shopping</Link>
            </li>
            <li className="subitem">
              <Link to="/services/food-preparation/">Food Preparation</Link>
            </li>
            <li className="subitem">
              <Link to="/services/housework/">Housework</Link>
            </li>
            <li className="subitem">
              <Link to="/services/overnight-care/">Overnight Care</Link>
            </li>
          </ul>
        </li>
        <li className="item">
          <Link to="/accreditation/">Accreditation</Link>
        </li>
        <li className="item">
          <Link to="/technology/">Technology</Link>
        </li>
        <li className="item">
          <Link to="/contact-us/">Contact Us</Link>
        </li>
        <li className="item">
          <Link to="/careers/">Careers</Link>
        </li>
      </ul>
    </NavStyles>
  );
}
