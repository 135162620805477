import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

const linecap = "square";
const linejoin = "initial";

const animation = css`
  --nudgeAmount: 4px;

  @keyframes nudge-right {
    0% {
      transform: translate(var(--posX), var(--posY));
    }
    50% {
      transform: translate(calc(var(--posX) + var(--nudgeAmount)), var(--posY));
    }
    100% {
      transform: translate(var(--posX), var(--posY));
    }
  }

  @keyframes nudge-left {
    0% {
      transform: translate(var(--posX), var(--posY));
    }
    50% {
      transform: translate(calc(var(--posX) - var(--nudgeAmount)), var(--posY));
    }
    100% {
      transform: translate(var(--posX), var(--posY));
    }
  }

  @keyframes nudge-up {
    0% {
      transform: translate(var(--posX), var(--posY));
    }
    50% {
      transform: translate(var(--posX), calc(var(--posY) - var(--nudgeAmount)));
    }
    100% {
      transform: translate(var(--posX), var(--posY));
    }
  }

  @keyframes nudge-upLeft {
    0% {
      transform: translate(var(--posX), var(--posY));
    }
    50% {
      transform: translate(
        calc(var(--posX) - var(--nudgeAmount)),
        calc(var(--posY) - var(--nudgeAmount))
      );
    }
    100% {
      transform: translate(var(--posX), var(--posY));
    }
  }

  @keyframes slide-down {
    from {
      transform: translate(var(--posX), calc(var(--startY) + var(--posY)));
    }
    to {
      transform: translate(var(--posX), var(--posY));
    }
  }
`;

const SvgStyles = styled.svg`
  --linecap: square;
  --linejoin: round;

  --nudgeDuration: 0.5s;
  --startX: -140px;
  --startY: -140px;
  --transitionDuration: 0.25s;

  * {
    transition-duration: var(--transitionDuration);
  }

  .pQ-upper {
    --posX: 45px;
    --posY: 1px;
    stroke-dasharray: 42 17 69 7;
  }

  .pQ-lower {
    --posX: 0px;
    --posY: 1px;
    stroke-dasharray: 37 18 143 6 999;
  }

  .pC {
    --posX: 43px;
    --posY: 0px;
  }

  .pL {
    --posX: 86px;
    --posY: 28px;
  }

  .gInscription {
    --posX: 17px;
    --posY: 115px;
    --scale: 1;
    --offsetX: 0;
    --offsetY: 0;
    fill: ${p => p.theme.colors.prussianBlue};
    opacity: 1;
    transform: translate(
        calc((1 - var(--scale) + var(--offsetX)) * var(--posX)),
        calc((1 - var(--scale) + var(--offsetY)) * var(--posY))
      )
      scale(var(--scale));
  }

  path {
    transform: translate(var(--posX), var(--posY));
  }

  .qcl path {
    fill: ${p => p.theme.colors.denim};
    stroke: ${p => p.theme.colors.paleNavy};
    stroke-width: 2px;
    stroke-linecap: var(--linecap);
    stroke-linejoin: var(--linejoin);
    transition-property: all;
    transition-duration: var(--transitionDuration);
    animation-duration: var(--nudgeDuration);
    /* animation-timing-function: ease; */
  }

  &.cued {
    .pQ-upper,
    .pQ-lower,
    .pC {
      transform: translate(calc(var(--startX) + var(--posX)), var(--posY));
    }
    .pL {
      transform: translate(var(--posX), calc(var(--startY) + var(--posY)));
    }
    .gInscription {
      opacity: 0;
    }
  }

  &.transitioning {
    .pQ-upper,
    .pQ-lower {
      transition-duration: calc(0.6 * var(--nudgeDuration));
    }
    .pC {
      transition-delay: calc(0.4 * var(--nudgeDuration));
      transition-duration: calc(0.6 * var(--nudgeDuration));
    }
    .pL {
      transition-delay: calc(0.8 * var(--nudgeDuration));
      transition-duration: calc(0.6 * var(--nudgeDuration));
    }
    .gInscription {
      transition-delay: calc(1.4 * var(--nudgeDuration));
      transition-duration: calc(1 * var(--nudgeDuration));
    }
  }

  &.transitioned {
    :hover {
      .qcl path {
        stroke-width: 2.5px;
        stroke: white;
      }
      .pQ-upper,
      .pQ-lower {
        transition-delay: 0;
      }
      .pC {
        transition-delay: 0;
      }
      .pL {
        transition-delay: 0;
      }
      .gInscription {
        --scale: 1.08;
        --offsetX: -0.5;
        --offsetY: -0.01;
        transition-delay: 0;
      }
    }
  }
`;

const gInscription = (
  <g className="gInscription">
    <g>
      <path
        className="q"
        d="M0,7.08C0,5.1,0.56,3.52,1.69,2.34c1.13-1.18,2.6-1.77,4.42-1.77c1.48,0,2.62,0.38,3.43,1.13
            s1.22,1.78,1.22,3.09c0,1.51-0.47,2.87-1.41,4.07c-1.19,1.51-2.79,2.27-4.8,2.27c-0.97,0-1.79-0.17-2.47-0.5
            c-0.68-0.33-1.2-0.8-1.55-1.4S0,7.92,0,7.08z M1.14,13.33l-0.01-0.2c0-0.57,0.19-1.04,0.56-1.41c0.37-0.38,0.83-0.56,1.39-0.56
            c0.25,0,0.54,0.04,0.87,0.13c0.33,0.09,0.84,0.28,1.53,0.58c1.33,0.58,2.15,0.93,2.44,1.05c0.3,0.12,0.56,0.18,0.79,0.18
            c0.33,0,0.59-0.11,0.8-0.32c0.21-0.21,0.37-0.59,0.49-1.14l0.46,0.15c-0.2,0.87-0.49,1.51-0.88,1.92
            c-0.39,0.41-0.82,0.62-1.29,0.62c-0.54,0-1.22-0.23-2.04-0.7l-1.63-0.91c-0.84-0.46-1.46-0.7-1.87-0.7
            c-0.22,0-0.42,0.05-0.59,0.16c-0.17,0.11-0.3,0.25-0.38,0.44c-0.09,0.18-0.15,0.49-0.18,0.93L1.14,13.33z M2.07,7.54
          c0,0.94,0.25,1.67,0.74,2.18c0.5,0.51,1.17,0.77,2.03,0.77c0.75,0,1.38-0.19,1.89-0.58C7.23,9.52,7.68,8.8,8.09,7.75
            c0.4-1.05,0.6-2.21,0.6-3.47c0-1.03-0.24-1.8-0.72-2.3C7.48,1.47,6.79,1.22,5.89,1.22c-0.76,0-1.39,0.2-1.91,0.59
            C3.46,2.2,3.02,2.91,2.64,3.95C2.26,4.98,2.07,6.18,2.07,7.54z"
      />
      <path
        className="u"
        d="M12.14,5.73l-0.18-0.42c0.66-0.48,1.23-0.85,1.73-1.11s0.87-0.4,1.13-0.4c0.17,0,0.29,0.04,0.37,0.13
            c0.08,0.09,0.12,0.24,0.12,0.45c0,0.25-0.09,0.7-0.26,1.34l-0.43,1.6c-0.16,0.6-0.3,1.18-0.41,1.72
            c-0.05,0.24-0.07,0.42-0.07,0.53c0,0.11,0.02,0.19,0.07,0.24c0.05,0.05,0.11,0.08,0.19,0.08c0.1,0,0.23-0.05,0.4-0.15
            c0.25-0.17,0.58-0.49,0.97-0.98s0.73-1.03,1.01-1.63c0.29-0.6,0.5-1.17,0.63-1.73s0.21-0.99,0.24-1.31
            c0.5-0.02,1.04-0.12,1.62-0.3l0.12,0.15c-0.25,0.76-0.54,1.73-0.86,2.92c-0.39,1.44-0.58,2.34-0.58,2.72
            c0,0.17,0.07,0.26,0.22,0.26c0.06,0,0.15-0.03,0.28-0.1c0.3-0.17,0.57-0.35,0.81-0.56l0.15,0.42l-1.33,0.96
            c-0.37,0.24-0.64,0.39-0.83,0.46c-0.19,0.07-0.35,0.1-0.48,0.1c-0.15,0-0.26-0.05-0.35-0.16c-0.09-0.1-0.13-0.26-0.13-0.46
            c0-0.24,0.09-0.69,0.26-1.33l0.43-1.6c-0.6,1.07-1.35,1.99-2.27,2.74c-0.66,0.54-1.2,0.81-1.62,0.81c-0.21,0-0.38-0.07-0.5-0.22
            c-0.12-0.14-0.18-0.37-0.18-0.69c0-0.17,0.02-0.34,0.05-0.51c0.04-0.22,0.14-0.63,0.31-1.25l0.21-0.78c0.33-1.2,0.5-1.94,0.5-2.23
            c0-0.1-0.02-0.17-0.06-0.22c-0.04-0.05-0.09-0.08-0.15-0.08C13.11,5.16,12.74,5.35,12.14,5.73z"
      />
      <path
        className="a"
        d="M27.43,9.18l0.15,0.48c-1.22,0.98-2.07,1.47-2.56,1.47c-0.16,0-0.28-0.05-0.36-0.15
            c-0.11-0.14-0.17-0.32-0.17-0.53c0-0.29,0.17-1.12,0.51-2.48l0.13-0.52C24.54,8.39,24.13,9,23.9,9.28
            c-0.38,0.47-0.74,0.84-1.08,1.11c-0.34,0.27-0.68,0.48-1.01,0.62c-0.2,0.08-0.37,0.12-0.51,0.12c-0.26,0-0.47-0.1-0.62-0.3
            c-0.15-0.2-0.23-0.54-0.23-1c0-0.56,0.11-1.24,0.32-2.02c0.21-0.79,0.44-1.4,0.68-1.83c0.13-0.22,0.4-0.5,0.81-0.82
            c0.78-0.62,1.33-0.99,1.65-1.14c0.32-0.14,0.67-0.21,1.04-0.21c0.27,0,0.53,0.03,0.8,0.09s0.52,0.15,0.78,0.27l0.68-0.37
            l0.24,0.15c-0.04,0.09-0.09,0.24-0.15,0.45L27,5.39l-0.82,3.53c-0.07,0.31-0.11,0.54-0.11,0.71c0,0.17,0.06,0.26,0.18,0.26
            C26.46,9.89,26.85,9.65,27.43,9.18z M25.5,4.92c-0.22-0.11-0.43-0.19-0.63-0.24c-0.2-0.05-0.4-0.08-0.59-0.08
            c-0.34,0-0.62,0.11-0.86,0.34c-0.24,0.23-0.49,0.8-0.77,1.72c-0.37,1.22-0.56,2.13-0.56,2.73c0,0.17,0.03,0.3,0.09,0.38
            c0.06,0.09,0.13,0.13,0.21,0.13c0.07,0,0.17-0.04,0.3-0.12c0.33-0.22,0.71-0.61,1.14-1.16s0.79-1.13,1.08-1.73s0.46-1.13,0.54-1.6
            L25.5,4.92z"
      />
      <path
        className="l"
        d="M32.53,9.16l0.22,0.4l-1.31,0.89c-0.52,0.32-0.88,0.51-1.08,0.58c-0.2,0.07-0.36,0.11-0.48,0.11
                c-0.15,0-0.28-0.07-0.37-0.21s-0.15-0.35-0.15-0.64c0-0.43,0.08-1,0.26-1.7l0.95-3.93c0.15-0.61,0.31-1.37,0.48-2.27
                c0.09-0.55,0.13-0.88,0.13-0.97s-0.04-0.17-0.13-0.21c-0.12-0.06-0.35-0.1-0.69-0.1h-0.42L30,0.67c1.05-0.12,2.07-0.34,3.03-0.67
                l0.18,0.16l-0.52,2.12l-1.05,4.45c-0.35,1.49-0.52,2.41-0.52,2.77c0,0.12,0.02,0.21,0.05,0.26c0.04,0.05,0.08,0.08,0.14,0.08
                c0.07,0,0.18-0.04,0.32-0.12C32.01,9.53,32.3,9.34,32.53,9.16z"
      />
      <path
        className="i"
        d="M33.83,6.04l-0.18-0.4c1.18-0.86,1.93-1.37,2.25-1.53c0.41-0.21,0.72-0.31,0.92-0.31
                c0.13,0,0.24,0.05,0.33,0.15c0.09,0.1,0.13,0.24,0.13,0.42c0,0.35-0.16,1.06-0.48,2.14l-0.24,0.79c-0.37,1.22-0.55,1.99-0.55,2.31
                c0,0.17,0.07,0.25,0.21,0.25c0.13,0,0.54-0.23,1.25-0.7l0.2,0.39l-1.52,1.01c-0.4,0.25-0.71,0.42-0.91,0.48
                c-0.14,0.05-0.27,0.08-0.4,0.08c-0.19,0-0.33-0.06-0.43-0.19c-0.1-0.12-0.15-0.33-0.15-0.6c0-0.37,0.08-0.83,0.23-1.41L35,7.19
                c0.25-0.87,0.37-1.44,0.37-1.71c0-0.13-0.06-0.19-0.17-0.19c-0.08,0-0.2,0.04-0.35,0.12C34.47,5.61,34.13,5.82,33.83,6.04z
                 M36.17,1.63c0-0.37,0.1-0.66,0.29-0.88s0.44-0.32,0.74-0.32c0.24,0,0.43,0.07,0.58,0.22C37.94,0.8,38.01,1,38.01,1.25
                c0,0.34-0.1,0.62-0.29,0.84c-0.19,0.22-0.44,0.33-0.74,0.33c-0.24,0-0.44-0.07-0.59-0.22S36.17,1.86,36.17,1.63z"
      />
      <path
        className="t"
        d="M43.23,8.96l0.18,0.41l-1.1,0.81c-0.57,0.39-1,0.64-1.29,0.77s-0.54,0.19-0.74,0.19
                c-0.25,0-0.45-0.08-0.6-0.25s-0.23-0.4-0.23-0.7c0-0.16,0.02-0.35,0.06-0.56c0.04-0.21,0.25-1.12,0.61-2.72
                c0.24-1.05,0.38-1.72,0.43-2h-1.62l0.1-0.4c0.47-0.1,1.04-0.27,1.72-0.51c0.18-0.97,0.27-1.65,0.27-2.06
                c0.52-0.14,1.14-0.33,1.84-0.59l0.15,0.18c-0.25,0.86-0.48,1.72-0.69,2.59h0.75c0.55,0,0.98-0.02,1.28-0.06l0.06,0.06l-0.1,0.37
                c-0.05,0.17-0.08,0.32-0.1,0.47c-0.43-0.04-0.86-0.06-1.28-0.06h-0.78C42,5.46,41.79,6.38,41.52,7.67c-0.21,1-0.32,1.61-0.32,1.85
                c0,0.13,0.03,0.23,0.1,0.3c0.07,0.07,0.15,0.11,0.26,0.11c0.15,0,0.33-0.06,0.54-0.18C42.52,9.52,42.9,9.26,43.23,8.96z"
      />
      <path
        className="y"
        d="M44.28,13.6l0.93-0.59l0.22,0.1c-0.01,0.1-0.02,0.18-0.02,0.24c0,0.32,0.09,0.58,0.27,0.77
                c0.18,0.2,0.41,0.29,0.7,0.29c0.25,0,0.49-0.08,0.73-0.23c0.24-0.16,0.46-0.42,0.66-0.79s0.42-0.94,0.67-1.73
                c0.29-1.03,0.58-2.1,0.86-3.2c-0.53,0.74-1,1.3-1.4,1.7s-0.77,0.68-1.11,0.85c-0.25,0.13-0.44,0.19-0.59,0.19
                c-0.2,0-0.37-0.11-0.51-0.33c-0.14-0.22-0.22-0.56-0.22-1.02c0-0.28,0.03-0.64,0.08-1.09l0.15-1.16c0.14-1.12,0.21-1.77,0.21-1.94
                c0-0.27-0.02-0.44-0.07-0.52c-0.05-0.08-0.11-0.12-0.2-0.12c-0.08,0-0.2,0.05-0.36,0.16c-0.39,0.25-0.68,0.48-0.87,0.69
                l-0.19-0.42c0.06-0.04,0.34-0.25,0.85-0.64S46,4.15,46.35,3.98c0.25-0.12,0.46-0.18,0.63-0.18c0.19,0,0.33,0.07,0.44,0.22
                c0.11,0.14,0.16,0.38,0.16,0.71c0,0.17-0.01,0.35-0.04,0.56l-0.32,2.62c-0.06,0.47-0.09,0.93-0.09,1.38
                c0,0.23,0.02,0.39,0.07,0.47c0.05,0.08,0.11,0.12,0.19,0.12c0.21,0,0.5-0.23,0.88-0.69c0.64-0.77,1.14-1.69,1.48-2.75
                c0.34-1.06,0.52-1.81,0.54-2.25c0.29-0.04,0.85-0.16,1.7-0.36l0.17,0.13c-0.2,0.5-0.39,1.1-0.58,1.79l-0.7,2.49
                c-0.44,1.57-0.82,2.7-1.14,3.39c-0.53,1.13-1.16,1.98-1.9,2.55s-1.42,0.86-2.06,0.86c-0.32,0-0.6-0.06-0.83-0.17
                s-0.41-0.26-0.51-0.44s-0.16-0.42-0.16-0.72V13.6z"
      />
      <path
        className="c"
        d="M65.49,9.15l-0.18,0.58c-0.81,0.47-1.39,0.79-1.74,0.94c-0.35,0.15-0.73,0.27-1.14,0.35
                c-0.4,0.08-0.83,0.12-1.26,0.12c-1.33,0-2.35-0.35-3.06-1.04c-0.71-0.69-1.07-1.68-1.07-2.96s0.27-2.39,0.8-3.33
                c0.53-0.94,1.33-1.71,2.39-2.33c1.06-0.61,2.17-0.92,3.32-0.92c0.56,0,1.09,0.06,1.6,0.19c0.5,0.13,0.91,0.29,1.23,0.49
                c-0.3,0.97-0.48,1.78-0.54,2.45h-0.52c0.02-0.19,0.04-0.37,0.04-0.53c0-0.2-0.02-0.5-0.06-0.92c-0.33-0.25-0.68-0.44-1.06-0.56
                c-0.38-0.12-0.83-0.18-1.35-0.18c-0.83,0-1.5,0.17-2,0.51c-0.5,0.34-0.9,0.9-1.2,1.69c-0.39,0.99-0.58,2.08-0.58,3.27
                c0,1.08,0.25,1.88,0.75,2.41c0.5,0.53,1.23,0.79,2.17,0.79c0.52,0,1.02-0.08,1.52-0.25c0.5-0.17,1.09-0.47,1.78-0.92L65.49,9.15z"
      />
      <path
        className="a"
        d="M73.96,9.18l0.15,0.48c-1.22,0.98-2.07,1.47-2.56,1.47c-0.16,0-0.28-0.05-0.36-0.15
                c-0.11-0.14-0.17-0.32-0.17-0.53c0-0.29,0.17-1.12,0.51-2.48l0.13-0.52c-0.6,0.94-1.01,1.55-1.24,1.83
                c-0.38,0.47-0.74,0.84-1.08,1.11c-0.34,0.27-0.68,0.48-1.01,0.62c-0.2,0.08-0.37,0.12-0.51,0.12c-0.26,0-0.47-0.1-0.62-0.3
                c-0.15-0.2-0.23-0.54-0.23-1c0-0.56,0.11-1.24,0.32-2.02c0.21-0.79,0.44-1.4,0.68-1.83c0.13-0.22,0.4-0.5,0.81-0.82
                c0.78-0.62,1.33-0.99,1.65-1.14c0.32-0.14,0.67-0.21,1.04-0.21c0.27,0,0.53,0.03,0.8,0.09s0.52,0.15,0.78,0.27l0.68-0.37
                l0.24,0.15c-0.04,0.09-0.09,0.24-0.15,0.45l-0.28,0.98l-0.82,3.53c-0.07,0.31-0.11,0.54-0.11,0.71c0,0.17,0.06,0.26,0.18,0.26
                C72.99,9.89,73.39,9.65,73.96,9.18z M72.04,4.92c-0.22-0.11-0.43-0.19-0.63-0.24c-0.2-0.05-0.4-0.08-0.59-0.08
                c-0.34,0-0.62,0.11-0.86,0.34c-0.24,0.23-0.49,0.8-0.77,1.72c-0.37,1.22-0.56,2.13-0.56,2.73c0,0.17,0.03,0.3,0.09,0.38
                c0.06,0.09,0.13,0.13,0.21,0.13c0.07,0,0.17-0.04,0.3-0.12c0.33-0.22,0.71-0.61,1.14-1.16s0.79-1.13,1.08-1.73s0.46-1.13,0.54-1.6
                L72.04,4.92z"
      />
      <path
        className="r"
        d="M75.6,11.13l-0.16-0.17c0.27-0.87,0.52-1.77,0.74-2.71c0.12-0.49,0.22-1.06,0.32-1.7
                c0.06-0.44,0.1-0.76,0.1-0.98c0-0.13-0.01-0.22-0.04-0.27c-0.03-0.05-0.07-0.07-0.12-0.07c-0.07,0-0.19,0.04-0.34,0.12
                c-0.25,0.14-0.51,0.32-0.79,0.56l-0.18-0.4c1.42-1.13,2.34-1.69,2.76-1.69c0.12,0,0.22,0.05,0.3,0.14c0.08,0.1,0.12,0.23,0.12,0.4
                c0,0.27-0.18,1.1-0.54,2.5l-0.09,0.39h0.09c0.36-0.84,0.73-1.54,1.12-2.12C79.29,4.55,79.62,4.18,79.9,4
                c0.21-0.13,0.45-0.19,0.73-0.19c0.14,0,0.27,0.02,0.4,0.07c-0.2,0.64-0.31,1.32-0.34,2.03l-0.17,0.1
                c-0.24-0.21-0.5-0.32-0.78-0.32c-0.44,0-0.85,0.3-1.21,0.89c-0.63,1.01-1.07,2.43-1.33,4.26C76.59,10.91,76.05,11.01,75.6,11.13z"
      />
      <path
        className="i"
        d="M81.21,6.04l-0.18-0.4c1.18-0.86,1.93-1.37,2.25-1.53c0.41-0.21,0.72-0.31,0.92-0.31
                c0.13,0,0.24,0.05,0.33,0.15c0.09,0.1,0.13,0.24,0.13,0.42c0,0.35-0.16,1.06-0.48,2.14l-0.24,0.79C83.58,8.53,83.4,9.3,83.4,9.62
                c0,0.17,0.07,0.25,0.21,0.25c0.13,0,0.54-0.23,1.25-0.7l0.2,0.39l-1.52,1.01c-0.4,0.25-0.71,0.42-0.91,0.48
                c-0.14,0.05-0.27,0.08-0.4,0.08c-0.19,0-0.33-0.06-0.43-0.19c-0.1-0.12-0.15-0.33-0.15-0.6c0-0.37,0.08-0.83,0.23-1.41l0.49-1.74
                c0.25-0.87,0.37-1.44,0.37-1.71c0-0.13-0.06-0.19-0.17-0.19c-0.08,0-0.2,0.04-0.35,0.12C81.85,5.61,81.51,5.82,81.21,6.04z
                 M83.55,1.63c0-0.37,0.1-0.66,0.29-0.88s0.44-0.32,0.74-0.32c0.24,0,0.43,0.07,0.58,0.22C85.32,0.8,85.39,1,85.39,1.25
                c0,0.34-0.1,0.62-0.29,0.84c-0.19,0.22-0.44,0.33-0.74,0.33c-0.24,0-0.44-0.07-0.59-0.22S83.55,1.86,83.55,1.63z"
      />
      <path
        className="n"
        d="M86.24,11.13l-0.18-0.19c0.1-0.26,0.19-0.51,0.25-0.73c0.23-0.8,0.4-1.36,0.49-1.68
                c0.17-0.59,0.3-1.2,0.41-1.84c0.11-0.63,0.16-1.04,0.16-1.24c0-0.15-0.06-0.22-0.17-0.22c-0.06,0-0.17,0.04-0.31,0.12
                c-0.3,0.16-0.56,0.33-0.79,0.51l-0.19-0.4l1.11-0.83c0.49-0.35,0.89-0.59,1.2-0.73c0.18-0.07,0.34-0.11,0.5-0.11
                c0.14,0,0.24,0.04,0.31,0.11c0.07,0.07,0.11,0.18,0.11,0.34c0,0.14-0.02,0.31-0.06,0.52c-0.11,0.56-0.22,1.07-0.35,1.54l-0.3,1.1
                c0.39-0.63,0.65-1.03,0.78-1.19c0.4-0.49,0.83-0.94,1.3-1.34c0.47-0.4,0.91-0.69,1.32-0.88c0.29-0.13,0.54-0.2,0.76-0.2
                c0.21,0,0.36,0.06,0.47,0.18c0.11,0.12,0.16,0.29,0.16,0.51c0,0.32-0.15,1.01-0.45,2.07L92.4,7.9c-0.2,0.72-0.3,1.29-0.3,1.69
                c0,0.19,0.06,0.28,0.17,0.28c0.08,0,0.21-0.05,0.37-0.14c0.31-0.18,0.55-0.35,0.73-0.52l0.18,0.43c-0.86,0.62-1.45,1.01-1.75,1.16
                c-0.41,0.21-0.74,0.32-1,0.32c-0.18,0-0.32-0.06-0.4-0.18c-0.09-0.12-0.13-0.32-0.13-0.62c0-0.32,0.1-0.81,0.29-1.49l0.23-0.81
                c0.21-0.72,0.34-1.2,0.39-1.44c0.13-0.57,0.2-0.93,0.2-1.09c0-0.09-0.03-0.16-0.08-0.21c-0.05-0.05-0.12-0.07-0.21-0.07
                c-0.24,0-0.58,0.21-1,0.63c-0.58,0.58-1.06,1.29-1.44,2.11c-0.38,0.83-0.65,1.78-0.81,2.88C87.23,10.9,86.69,10.99,86.24,11.13z"
      />
      <path
        className="g"
        d="M96.04,10.73c-0.26-0.23-0.39-0.47-0.39-0.72c0-0.2,0.08-0.34,0.23-0.44l0.58-0.34
                c0.26-0.15,0.46-0.29,0.62-0.41l-0.32,0.01c-0.39,0-0.76-0.09-1.13-0.27c-0.27-0.13-0.49-0.33-0.64-0.59S94.76,7.4,94.76,7
                c0-0.49,0.11-0.95,0.33-1.38c0.22-0.42,0.48-0.74,0.77-0.94c0.29-0.2,0.81-0.43,1.55-0.7c0.34-0.12,0.65-0.18,0.94-0.18
                c0.41,0,0.76,0.06,1.04,0.18c0.17,0.07,0.32,0.12,0.46,0.14c0.14,0.02,0.32,0.03,0.54,0.03l1.45-0.05l0.07,0.12L101.75,5
                c-0.29-0.05-0.66-0.08-1.11-0.08l-0.19,0.01c0.1,0.26,0.15,0.53,0.15,0.81c0,0.4-0.09,0.8-0.27,1.21
                c-0.18,0.41-0.39,0.72-0.64,0.93C99.43,8.1,99,8.33,98.37,8.58c-0.44,0.17-0.73,0.31-0.87,0.44c-0.14,0.12-0.21,0.24-0.21,0.34
                c0,0.09,0.04,0.18,0.11,0.26c0.1,0.12,0.3,0.29,0.59,0.49l0.97,0.7c0.42,0.3,0.72,0.58,0.88,0.84c0.16,0.26,0.24,0.54,0.24,0.86
                c0,0.68-0.31,1.25-0.92,1.71c-0.76,0.56-1.74,0.84-2.94,0.84c-1.06,0-1.84-0.18-2.35-0.54c-0.51-0.36-0.77-0.81-0.77-1.33
                c0-0.34,0.12-0.68,0.37-1.02c0.25-0.34,0.6-0.63,1.07-0.86C94.77,11.19,95.27,11,96.04,10.73z M96.36,11.01l-0.74,0.34
                c-0.29,0.16-0.52,0.31-0.69,0.46c-0.17,0.15-0.3,0.33-0.39,0.52c-0.09,0.2-0.14,0.41-0.14,0.63c0,0.43,0.19,0.79,0.56,1.08
                c0.37,0.29,0.88,0.44,1.53,0.44c0.63,0,1.13-0.14,1.48-0.42c0.35-0.28,0.53-0.62,0.53-1.01c0-0.22-0.08-0.42-0.23-0.61
                c-0.15-0.19-0.57-0.52-1.26-0.99L96.36,11.01z M96.26,6.96c0,0.47,0.1,0.81,0.3,1.03c0.2,0.22,0.48,0.33,0.85,0.33
                c0.29,0,0.55-0.09,0.78-0.27s0.43-0.48,0.59-0.91s0.25-0.89,0.25-1.38c0-0.45-0.1-0.78-0.3-1s-0.48-0.32-0.84-0.32
                c-0.51,0-0.91,0.26-1.19,0.78S96.26,6.33,96.26,6.96z"
      />
      <path
        className="l"
        d="M105.12,11.01l0.08-0.5h0.33c0.34,0,0.56-0.1,0.67-0.31c0.06-0.11,0.26-1.12,0.6-3.03l0.46-2.81
                c0.1-0.61,0.18-1.18,0.24-1.73c0.06-0.55,0.09-0.88,0.09-1.01c0-0.15-0.04-0.25-0.14-0.31c-0.09-0.06-0.27-0.09-0.54-0.09h-0.7
                l0.08-0.5c1.32,0.03,2.24,0.05,2.75,0.05c0.63,0,1.33-0.02,2.1-0.05l-0.09,0.5h-0.41c-0.43,0-0.7,0.04-0.8,0.12
                c-0.11,0.08-0.19,0.27-0.25,0.58c-0.05,0.23-0.19,1.06-0.43,2.49c-0.42,2.54-0.74,4.47-0.96,5.76c0.76,0.04,1.35,0.07,1.76,0.07
                c1.18,0,1.93-0.08,2.25-0.23c0.15-0.07,0.31-0.39,0.48-0.95l0.26-0.75h0.53c-0.28,1.23-0.45,2.12-0.51,2.7
                c-1.21,0-2.2-0.01-2.97-0.03l-2.14-0.04C106.62,10.94,105.71,10.96,105.12,11.01z"
      />
      <path
        className="t"
        d="M119.37,8.96l0.18,0.41l-1.1,0.81c-0.57,0.39-1,0.64-1.29,0.77s-0.54,0.19-0.74,0.19
                c-0.25,0-0.45-0.08-0.6-0.25s-0.23-0.4-0.23-0.7c0-0.16,0.02-0.35,0.06-0.56c0.04-0.21,0.25-1.12,0.61-2.72
                c0.24-1.05,0.38-1.72,0.43-2h-1.62l0.1-0.4c0.47-0.1,1.04-0.27,1.72-0.51c0.18-0.97,0.27-1.65,0.27-2.06
                c0.52-0.14,1.14-0.33,1.84-0.59l0.15,0.18c-0.25,0.86-0.48,1.72-0.69,2.59h0.75c0.55,0,0.98-0.02,1.28-0.06l0.06,0.06l-0.1,0.37
                c-0.05,0.17-0.08,0.32-0.1,0.47c-0.43-0.04-0.86-0.06-1.28-0.06h-0.78c-0.17,0.57-0.38,1.49-0.65,2.78
                c-0.21,1-0.32,1.61-0.32,1.85c0,0.13,0.03,0.23,0.1,0.3c0.07,0.07,0.15,0.11,0.26,0.11c0.15,0,0.33-0.06,0.54-0.18
                C118.66,9.52,119.03,9.26,119.37,8.96z"
      />
      <path
        className="d"
        d="M127.93,9.2l0.13,0.44l-1.34,0.98c-0.32,0.21-0.6,0.35-0.82,0.43c-0.15,0.05-0.28,0.08-0.4,0.08
                c-0.17,0-0.31-0.07-0.42-0.2s-0.17-0.31-0.17-0.53c0-0.13,0.01-0.26,0.04-0.4c0.11-0.56,0.22-1.04,0.33-1.44l0.32-1.18
                c-0.62,1.18-1.38,2.14-2.27,2.89c-0.68,0.58-1.24,0.86-1.66,0.86c-0.27,0-0.49-0.12-0.66-0.37c-0.17-0.24-0.26-0.64-0.26-1.19
                c0-0.56,0.09-1.2,0.28-1.9s0.42-1.32,0.72-1.84c0.17-0.3,0.37-0.54,0.62-0.72c0.75-0.55,1.35-0.92,1.8-1.11
                c0.32-0.14,0.67-0.21,1.05-0.21c0.25,0,0.57,0.05,0.95,0.16l0.25-1.6c0.08-0.53,0.12-0.83,0.12-0.9c0-0.12-0.05-0.21-0.14-0.26
                s-0.28-0.09-0.56-0.09c-0.16,0-0.35,0-0.58,0.01l0.09-0.44c0.9-0.02,1.92-0.24,3.08-0.67l0.2,0.14c-0.17,0.62-0.34,1.37-0.54,2.26
                l-1.26,6.01c-0.12,0.56-0.18,0.97-0.18,1.23c0,0.17,0.05,0.25,0.16,0.25C126.95,9.89,127.32,9.66,127.93,9.2z M125.99,4.83
                c-0.37-0.22-0.79-0.32-1.26-0.32c-0.42,0-0.72,0.08-0.89,0.25c-0.24,0.23-0.53,0.88-0.85,1.93c-0.32,1.05-0.48,1.97-0.48,2.76
                c0,0.16,0.03,0.28,0.08,0.35c0.06,0.07,0.13,0.11,0.22,0.11c0.1,0,0.24-0.05,0.41-0.16c0.29-0.18,0.61-0.48,0.95-0.91
                c0.46-0.57,0.82-1.14,1.1-1.71c0.28-0.57,0.47-1.12,0.59-1.65L125.99,4.83z"
      />
    </g>
  </g>
);

const gQCL = (
  <g className="qcl">
    <path
      className="pL"
      d="M5.13,79.25V77.8v-1.45c0-0.48,1.83-1,2.8-1.57c0.97-0.56,1.81-1.17,2.73-1.83c0.24-5.04,0.38-10.18,0.5-15.43
      c0.12-5.25,0.16-10.61,0.16-16.07c0-4.29-0.04-8.46-0.08-12.51c-0.05-4.05-0.12-7.98-0.22-11.79c-0.06-2.71-0.15-4.85-0.28-6.41
      c-0.13-1.56-0.3-2.54-0.5-2.95C10.14,7.54,10,7.33,9.8,7.15C9.61,6.96,9.37,6.81,9.09,6.69C8.8,6.57,7.99,6.43,6.64,6.27
      C5.29,6.11,3.75,5.92,1,5.72V3.38V1.03C5.13,1.13,8.03,1.2,11.36,1.25c3.33,0.05,6.51,0.07,9.58,0.07c2.98,0,6.03-0.02,8.85-0.07
      c2.81-0.05,5.62-0.12,8.37-0.21v2.34v2.34c-2.75,0.17-4.73,0.36-6.19,0.59c-1.47,0.22-2.48,0.48-2.87,0.75
      c-0.24,0.17-0.48,0.42-0.66,0.76C28.26,8.16,28.1,8.58,28,9.09c-0.1,0.51-0.2,2.27-0.28,5.27c-0.08,3.01-0.15,7.26-0.21,12.76
      L27.35,40.7l-0.17,13.57c0,2.55,0.03,5.29,0.09,8.24c0.06,2.94,0.16,6.09,0.29,9.43c2.43,0.13,4.54,0.23,6.33,0.29
      c1.79,0.06,3.27,0.1,4.44,0.1c3.5,0,6.72-0.14,9.66-0.42c2.94-0.28,5.61-0.7,8-1.25c0.49-1.67,0.98-3.71,1.46-5.71
      c0.48-2,0.96-3.92,1.44-6.68h2.45h2.45c-0.42,2.75-0.81,5.95-1.18,9.31c-0.37,3.37-0.72,7.15-1.04,11.17
      c-1.77,0.13-3.12,0.29-4.28,0.38c-1.16,0.09-2.14,0.17-3.08,0.2l-7.57,0l-14.97-0.35l-4.51-0.06l-4.51-0.07
      c-2.08-0.02-4.42,0-7.4,0.07C12.26,78.99,9.26,79.1,5.13,79.25z"
    />
    <path
      className="pQ-lower"
      d="M66.81,74.59c2.96-1.66,5.62-3.68,7.96-6.08c2.35-2.4,4.38-5.17,6.11-8.31c1.72-3.14,3.02-6.57,3.88-10.28
      c0.44-1.9,0.77-3.87,0.98-5.92H68.89c-0.18,4.22-0.7,8-1.58,11.32c-1.11,4.17-2.76,7.63-4.98,10.37c-2.21,2.74-4.8,4.8-7.78,6.17
      c-2.97,1.37-6.32,2.06-10.06,2.06c-2.99,0-5.7-0.3-8.13-0.91c-2.43-0.6-4.56-1.51-6.42-2.72c-1.86-1.21-3.56-2.8-5.09-4.78
      c-1.54-1.97-2.91-4.33-4.11-7.08c-1.21-2.74-2.11-5.79-2.72-9.14c-0.6-3.35-0.9-7.01-0.9-10.97c0-5.49,0.55-10.27,1.64-14.33
      c1.1-4.06,2.74-7.41,4.93-10.05c2.19-2.64,4.84-4.62,7.94-5.94c3.1-1.32,6.66-1.98,10.68-1.98c0.92,0,1.81,0.04,3.69,0.09v-6.1
      C44.7,0.02,44.4,0,44.1,0c-4.92,0-9.4,0.41-13.42,1.21c-4.02,0.81-7.59,2.02-10.72,3.64c-3.12,1.62-5.9,3.59-8.35,5.91
      c-2.44,2.32-4.55,4.99-6.31,8.01c-1.77,3.02-3.09,6.37-3.97,10.04C0.44,32.48,0,36.47,0,40.78c0,6.08,0.9,11.53,2.7,16.35
      c1.8,4.82,4.51,9.01,8.11,12.58c3.61,3.56,7.81,6.23,12.61,8.01c4.8,1.78,10.21,2.67,16.21,2.67c-2.45,1.08-4.8,2.31-7.05,3.69
      c-2.25,1.37-4.4,2.89-6.44,4.55l-1.87,1.54l-1.87,1.54l1.31,1.23l1.31,1.23c1.11-0.8,2.12-1.46,3.03-1.98
      c0.9-0.53,1.7-0.91,2.38-1.16c0.69-0.25,1.4-0.44,2.14-0.57c0.74-0.12,1.51-0.19,2.31-0.19c0.69,0,1.45,0.05,2.29,0.16
      c0.84,0.11,1.75,0.27,2.74,0.48c0.98,0.22,2.37,0.59,4.15,1.14c1.78,0.54,3.97,1.25,6.55,2.13c3.55,1.21,6.6,2.19,9.16,2.96
      c2.56,0.77,4.62,1.31,6.19,1.64c1.57,0.33,3.06,0.57,4.47,0.74c1.41,0.16,2.74,0.25,3.98,0.25c0.52,0,1.01-0.04,1.47-0.13
      c0.46-0.09,0.89-0.21,1.27-0.38c0.58-0.28,1.41-0.75,2.47-1.42c1.06-0.67,2.39-1.53,3.93-2.59l3.29-2.26l3.36-2.26V89v-1.7
      c-1.38,0.63-2.63,1.14-3.64,1.54c-1.01,0.4-1.97,0.7-2.65,0.87c-0.68,0.18-1.39,0.32-2.13,0.41c-0.74,0.09-1.51,0.13-2.3,0.13
      c-0.65,0-1.33-0.04-2.05-0.11c-0.72-0.08-1.46-0.19-2.25-0.34c-1.28-0.26-2.79-0.63-4.52-1.11c-1.73-0.48-3.68-1.08-5.85-1.78
      l-7.27-2.45l-7.28-2.45c-1.23-0.35-2.5-0.67-3.82-0.94c-1.32-0.27-2.69-0.5-4.1-0.69c5.19-0.28,9.81-0.9,13.89-1.87
      C60.33,77.55,63.85,76.24,66.81,74.59z"
    />

    <path
      className="pC"
      d="M18.16,37.89c0,5.32,0.75,10.98,2.33,15.28c1.58,4.3,3.95,8.1,7.1,11.38c2.91,3.05,6.37,5.33,10.38,6.86
      c4,1.52,8.55,2.28,13.65,2.28c1.97,0,3.87-0.1,5.72-0.31c1.84-0.2,3.63-0.51,5.34-0.92c1.72-0.41,3.53-0.97,5.43-1.68
      c1.9-0.71,3.89-1.58,5.97-2.6l0.69,0.64l0.7,0.64c-0.48,0.93-0.94,1.87-1.36,2.82c-0.43,0.95-0.83,1.9-1.2,2.87
      c-2.16,1.14-4.27,2.1-6.35,2.9c-2.08,0.8-4.11,1.44-6.11,1.91c-2,0.48-4.15,0.83-6.45,1.07c-2.3,0.24-4.75,0.35-7.35,0.35
      c-4.87,0-9.36-0.43-13.48-1.28c-4.12-0.86-7.86-2.14-11.22-3.85c-3.36-1.71-6.34-3.77-8.93-6.17c-2.59-2.41-4.79-5.16-6.6-8.26
      c-1.81-3.1-3.17-6.44-4.07-10C1.45,48.27,1,44.49,1,40.47c0-2.11,0.15-4.14,0.42-6.11H1.41c0.47-3.43,1.36-6.65,2.67-9.66
      c2.05-4.73,5.12-8.94,9.22-12.63c4.1-3.69,8.97-6.45,14.63-8.3C33.57,1.92,40,1,47.21,1c2.4,0,4.78,0.11,7.16,0.32
      c2.37,0.22,4.74,0.54,7.09,0.96c2.35,0.43,4.69,0.97,7.02,1.62c2.33,0.65,4.67,1.41,6.99,2.29l0.2,0.42l0.2,0.42
      c-0.34,1-0.63,1.99-0.87,2.95c-0.24,0.96-0.42,1.91-0.55,2.84c-0.25,1.62-0.47,3.36-0.66,5.37c-0.19,2.01-0.35,3.79-0.49,6.54h-2.34
      h-2.34c-0.11-2.75-0.22-5.14-0.33-6.76c-0.11-1.61-0.22-2.53-0.34-2.82c-0.11-0.29-0.42-0.66-0.93-1.15
      c-0.51-0.49-1.22-1.08-2.13-1.77c-0.91-0.7-1.98-1.33-3.21-1.89c-1.23-0.57-2.62-1.07-4.17-1.5c-1.55-0.44-3.18-0.77-4.89-0.98
      c-1.71-0.22-3.51-0.33-5.38-0.33c-4.45,0-8.45,0.69-11.99,2.06c-3.54,1.38-6.62,3.44-9.25,6.19c-2.63,2.75-4.6,6.04-5.91,9.87
      c-1.29,3.76-1.92,8.91-1.94,13.71"
    />
    <path
      className="pQ-upper"
      d="M8.48,7.91c3.17,1.27,5.78,3.18,7.84,5.73c2.55,3.12,4.46,6.94,5.73,11.44c1.27,4.5,1.91,9.69,1.91,15.57
      c0,1.14-0.03,2.25-0.00,3.95h16.87c0.21-1.95,0.31-3.97,0.31-6.06c0-4.01-0.41-7.72-1.22-11.11c-0.81-3.39-2.04-6.46-3.66-9.23
      c-1.62-2.76-3.6-5.22-5.91-7.38c-2.32-2.16-4.98-4.01-7.99-5.57c-3.01-1.55-6.45-2.72-10.31-3.49C8.36,0.42,4.36,0.05,0,0v6.1
      C3.16,6.32,5.99,6.92,8.48,7.91z"
    />
  </g>
);

export default function QclLogo() {
  const [cued, transitioning, transitioned] = [
    {
      className: "cued",
    },
    {
      className: "transitioning",
      onTransitionEnd(e) {
        if (e.target.classList.contains("gInscription")) {
          setLogoState(transitioned);
        }
      },
    },
    {
      className: "transitioned",
    },
  ];

  const [logoState, setLogoState] = useState(cued);

  useEffect(() => {
    setLogoState(transitioning);
  }, []);

  return (
    <SvgStyles
      {...logoState}
      version="1.1"
      x="0px"
      y="0px"
      height="100"
      viewBox="-4 0 160 130"
      xmlSpace="preserve"
    >
      {gQCL}
      {gInscription}
    </SvgStyles>
  );
}
