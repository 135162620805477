import { css } from "styled-components";

import OpenSansLightFont from "./OpenSans-Light.woff2";
import OpenSansLightItalicFont from "./OpenSans-LightItalic.woff2";
import OpenSansRegularFont from "./OpenSans-Regular.woff2";
import OpenSansRegularItalicFont from "./OpenSans-Italic.woff2";
import OpenSansSemiboldFont from "./OpenSans-SemiBold.woff2";
import OpenSansSemiboldItalicFont from "./OpenSans-SemiBoldItalic.woff2";
import OpenSansBoldFont from "./OpenSans-Bold.woff2";
import OpenSansBoldItalicFont from "./OpenSans-BoldItalic.woff2";
import OpenSansExtraboldFont from "./OpenSans-ExtraBold.woff2";
import OpenSansExtraboldItalicFont from "./OpenSans-ExtraBoldItalic.woff2";

import RalewayRegularFont from "./Raleway-Regular.woff";
import RalewayBlackFont from "./Raleway-Black.woff";
import RalewayBlackItalicFont from "./Raleway-BlackItalic.woff";
import RalewayThinFont from "./Raleway-Thin.woff";
import RalewayThinItalicFont from "./Raleway-ThinItalic.woff";
import RalewayItalicFont from "./Raleway-Italic.woff";
import RalewayMediumFont from "./Raleway-Medium.woff";
import RalewayBoldItalicFont from "./Raleway-BoldItalic.woff";
import RalewayExtraBoldItalicFont from "./Raleway-ExtraBoldItalic.woff";
import RalewayExtraLightItalicFont from "./Raleway-ExtraLightItalic.woff";
import RalewaySemiBoldItalicFont from "./Raleway-SemiBoldItalic.woff";
import RalewayExtraLightFont from "./Raleway-ExtraLight.woff";
import RalewayLightFont from "./Raleway-Light.woff";
import RalewayMediumItalicFont from "./Raleway-MediumItalic.woff";
import RalewayBoldFont from "./Raleway-Bold.woff";
import RalewayExtraBoldFont from "./Raleway-ExtraBold.woff";
import RalewaySemiBoldFont from "./Raleway-SemiBold.woff";
import RalewayLightItalicFont from "./Raleway-LightItalic.woff";

// const fontWeights = {
//   300: "Light",
//   normal: "Regular",
//   600: "Semibold",
//   bold: "Bold",
//   800: "Extrabold",
// };

function fontface(family, font, weight, style) {
  const w = weight || "normal";
  const s = style || "normal";
  return {
    fontface: `
      @font-face {
        font-family: "${family}";
        src: url(${font}) format("woff2");
        font-weight: ${w};
        font-style: ${s};
      }
    `,
    css: `
      font-family: "${family}";
      font-weight: ${w};
      font-style: ${s};
    `,
  };
}

// prettier-ignore
export const OpenSansLight           = fontface("Open Sans", OpenSansLightFont, 300, "normal");
// prettier-ignore
export const OpenSansLightItalic     = fontface("Open Sans", OpenSansLightItalicFont, 300, "italic");
// prettier-ignore
export const OpenSansRegular         = fontface("Open Sans", OpenSansRegularFont, "normal", "normal");
// prettier-ignore
export const OpenSansRegularItalic   = fontface("Open Sans", OpenSansRegularItalicFont, "normal", "italic");
// prettier-ignore
export const OpenSansSemibold        = fontface("Open Sans", OpenSansSemiboldFont, 600, "normal");
// prettier-ignore
export const OpenSansSemiboldItalic  = fontface("Open Sans", OpenSansSemiboldItalicFont, 600, "italic");
// prettier-ignore
export const OpenSansBold            = fontface("Open Sans", OpenSansBoldFont, "bold", "normal");
// prettier-ignore
export const OpenSansBoldItalic      = fontface("Open Sans", OpenSansBoldItalicFont, "bold", "italic");
// prettier-ignore
export const OpenSansExtrabold       = fontface("Open Sans", OpenSansExtraboldFont, 800, "normal");
// prettier-ignore
export const OpenSansExtraboldItalic = fontface("Open Sans", OpenSansExtraboldItalicFont, 800, "italic");

// prettier-ignore
export const RalewayRegular          = fontface("Raleway", RalewayRegularFont);
// prettier-ignore
export const RalewayBlack            = fontface("Raleway", RalewayBlackFont);
// prettier-ignore
export const RalewayBlackItalic      = fontface("Raleway", RalewayBlackItalicFont);
// prettier-ignore
export const RalewayThin             = fontface("Raleway", RalewayThinFont);
// prettier-ignore
export const RalewayThinItalic       = fontface("Raleway", RalewayThinItalicFont);
// prettier-ignore
export const RalewayItalic           = fontface("Raleway", RalewayItalicFont);
// prettier-ignore
export const RalewayMedium           = fontface("Raleway", RalewayMediumFont);
// prettier-ignore
export const RalewayBoldItalic       = fontface("Raleway", RalewayBoldItalicFont);
// prettier-ignore
export const RalewayExtraBoldItalic  = fontface("Raleway", RalewayExtraBoldItalicFont);
// prettier-ignore
export const RalewayExtraLightItalic = fontface("Raleway", RalewayExtraLightItalicFont);
// prettier-ignore
export const RalewaySemiBoldItalic   = fontface("Raleway", RalewaySemiBoldItalicFont);
// prettier-ignore
export const RalewayExtraLight       = fontface("Raleway", RalewayExtraLightFont);
// prettier-ignore
export const RalewayLight            = fontface("Raleway", RalewayLightFont);
// prettier-ignore
export const RalewayMediumItalic     = fontface("Raleway", RalewayMediumItalicFont);
// prettier-ignore
export const RalewayBold             = fontface("Raleway", RalewayBoldFont);
// prettier-ignore
export const RalewayExtraBold        = fontface("Raleway", RalewayExtraBoldFont);
// prettier-ignore
export const RalewaySemiBold         = fontface("Raleway", RalewaySemiBoldFont);
// prettier-ignore
export const RalewayLightItalic      = fontface("Raleway", RalewayLightItalicFont);
