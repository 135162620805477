import React from "react";
import styled from "styled-components";

/* eslint-disable */
import {
  up as mediaUp,
  down as mediaDown,
  between as mediaBetween,
  only as mediaOnly,
} from "styled-breakpoints";
/* eslint-enable */

const StyledFooter = styled.footer`
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  padding: 1rem;
  p {
    margin: 0.25em;
    padding: 0;
    width: 100%;
  }
  justify-content: space-between;
  background: ${p => p.theme.footerBg};
  font-size: 1.5rem;
  color: ${p => p.theme.colors.gray40};
  ${p => p.theme.fonts.Raleway.regular}

  ${mediaUp("tablet")} {
    p {
      width: auto;
    }
  }
`;

export default function Footer() {
  return (
    <StyledFooter>
      <p id="footer-copyright">© 2020 Quality Caring Ltd</p>
      <p id="footer-address">
        Registered in England - 7 Maple Grove, Lawrence Road, Hounslow,
        TW4&nbsp;6DR
      </p>
    </StyledFooter>
  );
}
