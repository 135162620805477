import React from "react";
import styled from "styled-components";

const DivStyles = styled.div`
  width: 0.75em;

  &:after,
  &:before,
  & div {
    background-color: ${p => p.theme.hamburgerColor};
    border-radius: 0.1em;
    content: "";
    display: block;
    height: 0.1em;
    margin: 0.15em 0;
    transition: all 0.2s ease-in-out;
    transform: translateY(0) rotate(0) scale(1);
  }

  /* &.hover { */
  /*   &:before, */
  /*   &:after, */
  /*   div { */
  /*     background-color: ${p => p.theme.hamburgerHoverColor}; */
  /*   } */
  /* } */

  &.open:before {
    transform: translateY(0.25em) rotate(135deg) scaleX(1.2);
  }

  &.open div {
    transform: scaleX(0.01);
  }

  &.open:after {
    transform: translateY(-0.25em) rotate(-135deg) scaleX(1.2);
  }
`;

/* eslint-disable-next-line react/prop-types */
export default function Hamburger({ color, open }) {
  return (
    <DivStyles color={color} className={open ? "open" : ""}>
      <div />
    </DivStyles>
  );
}
