import React from "react";
import "normalize.css";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";

import "animate.css/animate.css";
import bg from "../assets/images/bg.jpg";

import Favicon from "./Favicon";

import { OpenSansRegular, RalewayRegular } from "../assets/fonts/Fonts";
import Header from "./Header";
import Slider from "./Slider";
import Footer from "./Footer";

/* eslint-disable */
import {
  up as mediaUp,
  down as mediaDown,
  between as mediaBetween,
  only as mediaOnly,
} from "styled-breakpoints";
/* eslint-enable */

const StyledHeader = styled(Header)``;
const StyledSlider = styled(Slider)`
  /* padding-bottom: 2rem; */
`;

const GlobalStyles = createGlobalStyle`

  ${OpenSansRegular.fontface}
  ${RalewayRegular.fontface}

  * {
    box-sizing: border-box;
  }

  html {
    --header-height: 125px;
    --min-main-height: calc(var(--shadow-height) - var(--header-height) + var(--shadow-top) - 350px);
    --shadow: 50px 5px;
    --shadow-radius: 30%;
    --shadow-width: 400px;
    --shadow-height: 700px;
    --shadow-margin: 14px;
    --shadow-top: -60px;


    /* background-size: 450px; */
    /* background-attachment: fixed; */
    /* font-family: Arial, Helvetica, sans-serif; */
    ${OpenSansRegular.css}
    font-size: 10px;
    color: ${props => props.theme.fg};
  }


  body:before {
    display: block;
    height: 0.5rem;
    background: ${p => p.theme.primary};
    content: "";
  }

  ${mediaUp("lgDesktop")} {
    html {
      background-image: url(${bg});
    }
    body:before {
      height: 1rem;
    }
  }

  .dropshadow {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${p => p.theme.colors.firebrick};
  }

  h1 {
    font-size: 2.5rem;
    &.top {
      margin-top: 0;
      line-height: 2.5rem;
    }
  }

  h2 {
    font-size: 2rem;
    &.top {
      margin-top: 0.2em;
      line-height: 3rem;
    }
  }

  a {
    color: ${p => p.theme.colors.cobalt};
    text-decoration: none;
    &:visited {
      color: ${p => p.theme.colors.cobalt};
    }
    &:hover {
      color: ${p => p.theme.colors.prussianBlue};
    }
  }

  *:last-child {
    margin-bottom: 0;
  }

  .main {
    --mainPadding: 1rem;
    position: relative;
    min-height: var(--min-main-height);
    padding: var(--mainPadding);
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: justify;
    display: grid;
    column-gap: 1rem;
    row-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
  }

  .underline {
    text-decoration: underline;
  }

  .lead {
    ${RalewayRegular.css}
    font-size: 1.7rem;
    border-top: 1px solid ${p => p.theme.colors.gainsboro};
    border-bottom: 1px solid ${p => p.theme.colors.gainsboro};
    padding: 1rem 1rem;
    margin: 2rem 0;
  }

  .col1 {
    grid-column: span 1;
  }

  .col2 {
    grid-column: span 2;
  }

  .col3 {
    grid-column: span 3;
  }

  .full-width {
    width: 100%;
  }

  .full-height {
    height: 100%;
  }

  .ratio1\\:1 > svg:first-child {
    display: none;
  }

  .ratio1\\:1 {
    position: relative;
    display: grid;
    & > svg:first-child {
      display: initial;
    }
    & > * {
      grid-area: 1/1;
    }
    & > .target {
      position: absolute;
      height: 100%;
      overflow-y: auto;
    }
  }

  ${mediaDown("phone")} {
    .ratio1\\:1 {
      position: relative;
      display: grid;
      & > svg:first-child {
        display: initial;
      }
      & > * {
        grid-area: 1/1;
      }
      & > .target {
        position: absolute;
        height: 100%;
        overflow-y: auto;
      }
    }
    .p-no-main-padding {
      margin: calc(-1 * var(--mainPadding));
    }
    .p-no-main-vpadding {
      margin-top: calc(-1 * var(--mainPadding));
      margin-bottom: calc(-1 * var(--mainPadding));
    }
    .p-no-main-hpadding {
      margin-left: calc(-1 * var(--mainPadding));
      margin-right: calc(-1 * var(--mainPadding));
    }
    .p-no-main-tpadding {
      margin-top: calc(-1 * var(--mainPadding));
    }
    .p-no-main-bpadding {
      margin-bottom: calc(-1 * var(--mainPadding));
    }
  }

  ${mediaUp("tablet")} {
    .t-dropshadow {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    }
    .t-col1 {
      grid-column: span 1;
    }

    .t-col2 {
      grid-column: span 2;
    }

    .t-col3 {
      grid-column: span 3;
    }

    .t-full-height {
      height: 100%;
    }
    .t-no-main-padding {
      margin: calc(-1 * var(--mainPadding));
    }
    .t-no-main-vpadding {
      margin-top: calc(-1 * var(--mainPadding));
      margin-bottom: calc(-1 * var(--mainPadding));
    }
    .t-no-main-hpadding {
      margin-left: calc(-1 * var(--mainPadding));
      margin-right: calc(-1 * var(--mainPadding));
    }
    .t-no-main-rpadding {
      margin-right: calc(-1 * var(--mainPadding));
    }
  }

  ${mediaUp("desktop")} {
    .d-col1 {
      grid-column: span 1;
    }

    .d-col2 {
      grid-column: span 2;
    }

    .d-col3 {
      grid-column: span 3;
    }

    .d-full-height {
      height: 100%;
    }
  }

  ${mediaUp("lgDesktop")} {
    ${StyledHeader} {
      height: var(--header-height);
    }
    .main {
      --mainPadding: 2rem;
      column-gap: 2rem;
      row-gap: 2rem;
    }
    .lead {
      font-size: 1.8rem;
    }
    .l-col1 {
      grid-column: span 1;
    }

    .l-col2 {
      grid-column: span 2;
    }

    .l-col3 {
      grid-column: span 3;
    }

`;

const theme = {
  breakpoints: {
    phone: "0px",
    tablet: "768px",
    desktop: "992px",
    lgDesktop: "1200px",
  },
  get fg() {
    return this.colors.gray20;
  },
  get bg() {
    return this.colors.whiteSmoke;
  },
  get primary() {
    return this.colors.cobalt;
  },
  get footerBg() {
    return this.colors.merino;
  },
  get hamburgerColor() {
    return this.fg;
  },
  get hamburgerHoverColor() {
    return this.activeColor;
  },
  get gradientFrom() {
    return this.colors.hawkesBlue;
  },
  get gradientTo() {
    return this.colors.paleNavy;
  },
  get activeColor() {
    return this.colors.lavenderBlush;
  },
  get activeBackgroundColor() {
    return this.colors.firebrick;
  },
  fonts: {
    OpenSans: {
      regular: OpenSansRegular.css,
    },
    Raleway: {
      regular: RalewayRegular.css,
    },
  },
  colors: {
    whisper: "#e6e6e6",
    darkGray: "#adadad",
    chineseWhite: "#e0e0e0",
    firebrick: "#B22222",
    cobalt: "#004E9E",
    hawkesBlue: "#C8DCFA",
    paleNavy: "#EAEBF6",
    lavenderBlush: "#FFF0F5",
    whiteSmoke: "#F5F5F5",
    merino: "#DEDAD3",
    gray40: "#666",
    gray20: "#333",
    gainsboro: "#e1e1e1",
    prussianBlue: "#002852",
    denim: "#276ABA",
  },
};

const StyledWrapper = styled.div`
  background: ${p => p.theme.bg};
  position: relative;

  ${mediaUp("lgDesktop")} {
    width: 1170px;
    margin: auto;
    &:before {
      content: "";
      position: absolute;
      height: var(--shadow-height);
      width: var(--shadow-width);
      border-radius: var(--shadow-radius);
      z-index: -1;
      box-shadow: 0 0 var(--shadow) rgba(0, 0, 0, 0.5);
      margin-left: var(--shadow-margin);
      left: 0;
      top: var(--shadow-top);
    }
    &:after {
      content: "";
      position: absolute;
      height: var(--shadow-height);
      width: var(--shadow-width);
      border-radius: var(--shadow-radius);
      z-index: -1;
      box-shadow: 0 0 var(--shadow) rgba(0, 0, 0, 0.5);
      margin-right: var(--shadow-margin);
      right: 0;
      top: var(--shadow-top);
    }
  }
`;

// const StyledHeader = styled(Header)`
//   ${mediaUp("lgDesktop")} {
//     height: 500px;
//   }
// `;

/* eslint-disable-next-line react/prop-types */
export default function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Favicon />
      <GlobalStyles />
      <StyledWrapper id="wrapper">
        <StyledHeader />
        <StyledSlider />
        <div className="main">{children}</div>
        <Footer />
      </StyledWrapper>
    </ThemeProvider>
  );
}
