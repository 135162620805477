import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

require("swiper/swiper.scss");
require("swiper/components/navigation/navigation.scss");
// import "swiper/swiper-bundle.css";

const SwiperStyles = styled(Swiper)`
  --swiper-navigation-size: 32px;

  /* .swiper-container { */
  /*   -webkit-transform: translateZ(0); */
  /*   -webkit-backface-visibility: hidden; */
  /* } */

  .swiper-wrapper {
    // fix for ssr
    transform: translate3d(-1170px, 0px, 0px);
  }

  /* .swiper-slide { */
  /*   transform: translate3d(0, 0, 0) !important; */
  /*   transform-style: preserve-3d; */
  /*   backface-visibility: hidden; */
  /* } */

  .swiper-button-prev,
  .swiper-button-next {
    color: ${p => p.theme.colors.whiteSmoke};
    opacity: 0.6;
    &:hover {
      /* color: ${p => p.theme.activeBackgroundColor}; */
      opacity: 1;
    }
  }

  .swiper-pagination-bullet {
    background-color: ${p => p.theme.colors.whiteSmoke};
    /* background-color: white; */
    opacity: 0.6;
  }

  .swiper-pagination-bullet:hover,
  .swiper-pagination-bullet-active {
    /* background-color: ${p => p.theme.activeBackgroundColor}; */
    opacity: 1;
  }

  img {
    user-drag: none;
    user-select: none;
    pointer-events: none;
  }
`;

// const slides = [slide1, slide2, slide3, slide4, slide5, slide6, slide7];

SwiperCore.use([Autoplay, Navigation]);

export default function Slider({ className }) {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativePath: { regex: "/slide[^16].jpg/" } }
        sort: { fields: [relativePath] }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 1170, quality: 95) {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  `);

  const settings = {
    autoplay: {
      delay: 8000,
      disableOnInteraction: true,
    },
    touchStartPreventDefault: false,
    loop: true,
    // pagination: { clickable: true },
    navigation: {},
  };

  return (
    <SwiperStyles className={className} {...settings}>
      {data.allFile.edges.map(e => (
        <SwiperSlide>
          <Img fluid={e.node.childImageSharp.fluid} />
        </SwiperSlide>
      ))}
    </SwiperStyles>
  );
}
