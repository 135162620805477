import React from "react";
import styled from "styled-components";
import Nav from "./Nav";
import QCLLogo from "./QclLogo";

/* eslint-disable */
import {
  up as mediaUp,
  down as mediaDown,
  between as mediaBetween,
  only as mediaOnly,
} from "styled-breakpoints";
/* eslint-enable */

// import QclLogo from "../assets/images/qcl-logo.png";

const StyledNav = styled(Nav)``;

const HeaderStyles = styled.header`
  background-image: linear-gradient(
    to bottom,
    ${p => p.theme.gradientFrom} 0%,
    ${p => p.theme.gradientTo} 100%
  );

  color: ${p => p.theme.primary};

  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;

  #desc {
    display: none;
  }

  #logo {
    /* display: block; */
    margin-left: 1rem;
    align-self: center;
    padding: 0.2rem 0;
    svg {
      height: 4.7rem;
    }
  }

  ${StyledNav} {
    display: contents;
    & > ul {
      display: contents;
    }
    & .item {
      grid-column: 1 / span 2;
    }
    & .toggle {
      justify-self: end;
      align-self: center;
    }
  }

  ${mediaUp("tablet")} {
    padding: 0 1rem;
    ${StyledNav} {
      .menu {
        display: flex;
      }
      .item {
        display: initial;
      }
    }

    #logo {
      margin-left: 0;
      grid-column: 1 / span 1;
      grid-row: 1 / span 2;
      /* padding: 1.7rem 0; */
    }
  }

  ${mediaUp("lgDesktop")} {
    display: grid;
    justify-content: space-between;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(2, auto);
    padding: 0 2rem;
    #logo {
      svg {
        height: 10rem;
      }
      grid-column: 1 / span 1;
      grid-row: 1 / span 2;
      width: 100%;
      height: 100%;
      /* padding: 1.7rem 0; */
      padding: calc(0.5rem + 5%) 0;
    }

    #desc {
      display: initial;
      grid-column: 2 / span 1;
      grid-row: 1 / span 1;
      align-self: start;
      justify-self: end;
      padding: 1.5rem;
      font-size: 2rem;
      font-weight: bold;
    }

    ${StyledNav} .menu {
      grid-column: 2 / span 1;
      grid-row: 1 / span 2;
      align-self: end;
    }
  }
`;

export default function Header({ className }) {
  return (
    <HeaderStyles className={className}>
      <a id="logo" href="/">
        <QCLLogo />
      </a>
      <div id="desc">Specialist Provider of Domiciliary Care</div>
      <StyledNav />
    </HeaderStyles>
  );
}
