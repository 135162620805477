// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accreditation-js": () => import("./../../../src/pages/accreditation.js" /* webpackChunkName: "component---src-pages-accreditation-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-food-preparation-js": () => import("./../../../src/pages/services/food-preparation.js" /* webpackChunkName: "component---src-pages-services-food-preparation-js" */),
  "component---src-pages-services-housework-js": () => import("./../../../src/pages/services/housework.js" /* webpackChunkName: "component---src-pages-services-housework-js" */),
  "component---src-pages-services-medication-js": () => import("./../../../src/pages/services/medication.js" /* webpackChunkName: "component---src-pages-services-medication-js" */),
  "component---src-pages-services-overnight-care-js": () => import("./../../../src/pages/services/overnight-care.js" /* webpackChunkName: "component---src-pages-services-overnight-care-js" */),
  "component---src-pages-services-personal-care-js": () => import("./../../../src/pages/services/personal-care.js" /* webpackChunkName: "component---src-pages-services-personal-care-js" */),
  "component---src-pages-services-shopping-js": () => import("./../../../src/pages/services/shopping.js" /* webpackChunkName: "component---src-pages-services-shopping-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */)
}

